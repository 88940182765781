import React from 'react';
import TitleSection from '../components/home/TitleSection';
import MainLayout from '../components/MainLayout';
import Row from 'react-bootstrap/Row';
import '../css/ServicePage.css';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';
import SectionCorner from '../components/common/SectionCorner';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Paragraph from '../components/common/Paragraph';
import Colors from '../utils/Colors';

/**
 * Service page (not mobile units)
 */
class ServicePage extends React.Component {

	/**
	 * Constructor
	 * @param {*} props Component props
	 */
	constructor(props) {

		super(props);

		this.mainLayout = React.createRef();

		this.onSlideChange = this.onSlideChange.bind(this);

		this.openVideo = this.openVideo.bind(this);
	}

	/**
	 * on carousel slide : activate autoheight
	 * @param {SlideEvent} e Slide event
	 */
	onSlideChange(e) {
		/*this.setState({
			autoHeight: true
		});*/
	}

	/**
	 * Open video popover
	 * @param {string} url Video Url
	 * @param {string} type Video filetype
	 */
	openVideo(url, type) {
		this.mainLayout.current.openVideo(url, type);
	}

	/**
     * Check if a text is empty (empty paragraph for example). Necessary beacause Kontent return "<p><br></p>" when rich text is not filled.
     * @param {string} text Text to check
     */
	isTextEmpty(text) {
		return !(text && text !== '<p><br></p>');
	}

	render() {
		let data = this.props.pageContext.data.elements;

		let color = Colors.getColorNameByNumber(this.props.pageContext.index);
		let menuIndex = this.props.pageContext.index;
		//console.log("PAGE INDEX : "+this.props.pageContext.index)
		if (this.props.pageContext.index === 1) {
			//color = Colors.getColorNameByNumber();
			//menuIndex = 1;
		}
		else {
			//color = 'emg-yellow';
			//menuIndex = 2;
		}

		let sectionTitle = "";
		if (!this.isTextEmpty(data.content_title.value)){
			sectionTitle = <div className="servicepage-content-title emg-title">{data.content_title.value}</div>;
		}

		return (
			<MainLayout scrollSnap={false} activeItem={menuIndex} ref={this.mainLayout}>
				{/* TITLE SECTION */}
				<Row className="nearly-fullheight fullheight">
					<TitleSection
						background={data.background.value[0].url}
						title={data.title.value}
						lineColor={color}
						text={data.subtitle.value}
					/>
				</Row>
				{/* BACK BTN */}
				<Row className="servicepage-section">
					<Col xs={{ span: 10, offset: 1 }}>
						<Link to={this.props.pageContext.parentUrl + '#' + this.props.pageContext.pagePath} className="servicepage-back">
							<div className="emg-arrow-left" /> BACK
						</Link>
					</Col>
				</Row>
				{/* MAIN SECTION */}
				<Row className="servicepage-section">
					<Col xs={{ span: 10, offset: 1 }}>
						{sectionTitle}
						<div className="emg-text" dangerouslySetInnerHTML={{ __html: data.content_text.value }} />
					</Col>
				</Row>
				{/* PARAGRAPHS SECTION */}
				{data.paragraphs.value.map((paragraph, index) => (
					<Paragraph index={index} data={paragraph} color={color} key={'service-paragraph-' + index} openVideo={this.openVideo} />
				))}

				<Row className="servicepage-spacer servicepage-section" />

				{/* PICTURES SECTION */}
				<Row className=" servicepage-carousel-section servicepage-section">
					<SectionCorner position="topleft" fillColor="#0d0d0e" className="servicepage-carousel-section-corner" />
					<Col xs={{ span: 10, offset: 1 }}>
						<AliceCarousel
							infinite={true}
							autoPlay={true}
							duration={1000}
							autoPlayInterval={4000}
							dotsDisabled={false}
							buttonsDisabled={true}
							onSlideChange={this.onSlideChange}>
							{data.carousel_pictures.value.map((picture, index) => (
								<div
									className="servicepage-carousel-picture"
									style={{ backgroundImage: 'url(' + picture.url + ')' }}
									key={'service-carousel-' + index}
									alt={'service-carousel-' + index}
								/>
							))}
						</AliceCarousel>
					</Col>
				</Row>
			</MainLayout>
		);
	}
}

export default ServicePage;
